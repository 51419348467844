import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PropertyService } from "../property.service";

import { PaymentService } from "../payment.service";
import { UserService } from "../user.service";
import { SettingsService } from "../settings.service";
import { KeycloakService } from "keycloak-angular";
import { SsrService } from "../ssr-service";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public isLoggedIn: boolean = false;

  private _logout$: Subject<void> = new Subject<void>();
  public logout$: Observable<void> = this._logout$;

  constructor(
    private propertyService: PropertyService,
    private userService: UserService,
    private keycloakService: KeycloakService,
    private settingsService: SettingsService,
    public router: Router,
    private paymentService: PaymentService,
    private ssrService: SsrService
  ) {}

  // To ensure that the user data is only downloaded once
  private userDownloadStarted: boolean = false;

  // Retrieves relevant user data from our api
  public getUserData() {
    // In case we started the download of user data already once we do not want to start it again
    if (this.userDownloadStarted) {
      return;
    }
    this.userDownloadStarted = true;

    this.userService.getUserProfile();
    this.userService.getAgentProfile();
    this.userService.getClientProfile();

    // Find out whether the user has properties to show the corresponding menu
    this.propertyService.getMyProperties();

    // The current subscription
    this.paymentService.getMySubscription(true);

    this.settingsService.getAllSearches();
  }

  /**
   * This function resets all of the user data from the application
   */
  private removeUserData() {
    // ToDo add a test for this to ensure that the userdata will be downloaded again after we logged back in
    this.userDownloadStarted = false;
  }

  public login() {
    const location = this.ssrService.getLocation();
    if (location) {
      this.keycloakService.login({
        redirectUri: location.href,
      });
    }
  }

  public register() {
    this.keycloakService.register();
  }

  /**
   * The logout functionality via keycloak
   */
  public logout() {
    this.keycloakService.logout();
    this._logout$.next();
    this.removeUserData();
  }
}
