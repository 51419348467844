import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { SearchParams, UserSearch } from "../models/settings.models";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  // A list of ids and name for the different searches
  public userSearches: UserSearch[] = [];

  private emptySearchFields: SearchParams = {
    polygon: null,
    mapBounds: null,
    propertyType: [],
    price: {
      from: null,
      to: null,
    },
    municipality: [],
    city: [],
    bedrooms: {
      from: null,
      to: null,
    },
    bathrooms: {
      from: null,
      to: null,
    },
    area: {
      from: null,
      to: null,
    },
    garages: {
      from: null,
      to: null,
    },
    yearBuilt: {
      from: null,
      to: null,
    },
    vefa: null,
  };
  // An object to contain the current searchFields
  public searchFields: SearchParams = JSON.parse(JSON.stringify(this.emptySearchFields));

  // A subject that triggers changes in the searchFields
  // It is used so that the differencet components can trigger their corresponding functions
  // The host string that triggers the change should be send as string. This to avoid infinite loops for some of the behaviours
  public searchFieldsChangeSubject: Subject<string> = new Subject();

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  /**
   * This function downloads all the searches from the backend
   * It only returns the corresponding id and name
   * In case more information is required the downloadSearchById needs to be called
   * @returns all the searches of the currently logged in user (identifier and name)
   */
  public downloadAllSearches(): Observable<UserSearch[]> {
    return this.http.get<UserSearch[]>(
      this.dataService.backendUrl + "/settings/search",
      this.httpOptions
    );
  }

  /**
   * Functions that returns all the current user searches. In case the searches have not been downloaded yet
   * The download is executed
   * @returns a promise for the result
   */
  public async getAllSearches(): Promise<UserSearch[]> {
    if (!this.userSearches.length) {
      await firstValueFrom(this.downloadAllSearches()).then((data) => {
        this.userSearches = data;
      });
    }
    return this.userSearches;
  }

  /**
   * Functions to upload a new search to the backend
   * @param search the model that contains the name of the search and the search parameters themselves
   * @returns the corresponding Observable
   */
  public uploadSearch(search: UserSearch) {
    return this.http.post(
      this.dataService.backendUrl + "/settings/search",
      search,
      this.httpOptions
    );
  }

  /**
   * Functions to download a search from the backend
   * @param id the id of the search in question
   * @returns the corresponding Observable
   */
  public downloadSearchById(id: string): Observable<UserSearch> {
    return this.http.get<UserSearch>(
      this.dataService.backendUrl + "/settings/search/" + id,
      this.httpOptions
    );
  }

  /**
   * Modifiy notification for user search
   * @param id the id of the search in question
   * @param notifications: boolean whether we would like to enable or disable the notification
   * @returns the correspoding Observable
   */
  public toggleSearchNotifications(id: string, notifications: boolean) {
    return this.http.put(
      this.dataService.backendUrl + "/settings/search/" + id,
      { notifications: notifications },
      this.httpOptions
    );
  }

  /**
   * Function used to delete a specific search with a given id
   * @param id the id of the search to be deleted
   * @returns the corresponding observable
   */
  public deleteSearch(id: string) {
    return this.http.delete(
      this.dataService.backendUrl + "/settings/search/" + id,
      this.httpOptions
    );
  }

  public resetService() {
    this.userSearches = [];
    this.searchFields = JSON.parse(JSON.stringify(this.emptySearchFields));
  }
}
